@import '../../../themes/_variables.css';

.application {
  width: 100%;
  min-height: 100%;
  padding-top: var(--size_mobileHeader_height);
  color: var(--color_text-primary);
  background: var(--background-page);

  @mixin tvDevice {
    min-height: 100vh;
    padding-top: var(--size_header_height-tv);
  }

  @media (--mq-from-tablet) {
    padding-top: var(--size_header_height);
  }

  @mixin isDark {
    color: var(--color_darkmode-textcolor-primary);
    background: var(--color_darkmode-background-page);
  }

  &--skip-link {
    position: absolute;
    top: 1.125rem;
    left: 1rem;
    z-index: var(--z-index-6);
  }

  /**
   * Sometimes, the header doesn't have navigation (non-connected prospect mode e.g)
   * This CSS class manage the position of application in this case
   * because header has a different height.
   */
  &--resetNav {
    padding-top: 2.8125rem;

    @media (--mq-from-laptop) {
      padding-top: var(--size_header_height);
    }
  }

  /**
   * If application render a template `landing`, maybe there is a template `cover`.
   * In this case, displays the cover stiched to top of the viewport
   * because header is transparent.
   */
  &--cover {
    padding-top: var(--size_mobileHeader_height);

    @media (--mq-from-tablet) {
      padding-top: var(--size_alert_height);
    }

    @mixin tvDevice {
      padding-top: 0;
    }
  }

  &--noHeader {
    padding-top: 0;
    @mixin tvDevice {
      padding-top: 0;
    }
  }

  &--hideHeader {
    display: none;
  }

  /**
   * Sometimes, the scrool of application needs to be blocked
   * if a content is displaying above itself.
   */
  &--noScroll {
    overflow: hidden;
  }

  &--hidden {
    display: none;
    background-color: var(--color_darkmode-background-page);
  }

  /**
   * Allows to display header & footer in an empty page
   * without having a "stitch effect"
   */
  &__mainContent {
    min-height: calc(100vh - var(--size_mobileHeader_height));

    @media (--mq-from-laptop) {
      min-height: calc(100vh - var(--size_header_height));
    }

    &--with-skip-link {
      scroll-margin: var(--scroll-margin-skip-link) 0 0 0;
      /* Graceful degradation for browsers without "scroll-margin" support, e.g.: Chrome<69 */
      @supports not (scroll-margin: 1px) {
        padding-top: var(--scroll-margin-skip-link);
        margin-top: calc(-1 * var(--scroll-margin-skip-link));
      }
    }

    &--immersiveFull {
      min-height: 0;

      @media (--mq-from-laptop) {
        min-height: 0;
      }
    }
  }

  &__outlineRed {
    outline: 3px solid var(--color_secondary) !important;
  }

  &__backgroundRed {
    color: var(--color_white) !important ;
    background-color: var(--color_secondary) !important;
    border-radius: var(--border-radius-extraLarge) !important;
  }

  &__GDPRConsent {
    top: 0;
    display: flex;
    width: 100vw;
    max-width: unset;
    height: auto;
    min-height: 100vh;
    padding: 0 25.5rem 10vh;
  }
}

/* used to remove FOUC in development mode */
:global #application {
  display: block;
}
