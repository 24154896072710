.immersiveNav {
  /* Increase nav button touch area for improved UX */
  --size-nav-button: 3.75rem;
  --size-nav-button-padding: 0 0.9375rem;

  &--isDetailLight {
    --size-nav-button: 4.875rem;
    --size-nav-button-padding: 0 2rem;
  }
  position: sticky;
  top: 0;
  z-index: var(--z-index-nav);
  display: flex;
  align-items: center;

  height: 0;
  padding: var(--size-nav-button-padding);

  &__prev {
    left: 0;

    svg {
      padding: 0.5rem;
      background: var(--color_immersiveNav-circle-bg);
      border-radius: 50%;
      fill: var(--color_white-80);
      transform: rotate(180deg);
    }
  }

  &__close {
    right: 0;
    opacity: 1;
    transition: opacity 250ms ease;

    svg {
      circle {
        fill: var(--color_immersiveNav-circle-bg);
        fill-opacity: 1;
      }
    }

    &--disabled {
      cursor: default;

      &:hover svg,
      svg {
        fill: hsl(0, 0%, 50%); /* #808080 */
      }
    }
  }

  &__prev,
  &__close {
    @mixin size var(--size-nav-button);
    position: absolute;
    top: 0;

    svg {
      @mixin size 1.875rem;
      transition: opacity 200ms;

      path {
        fill: var(--color_white);
        stroke: var(--color_white);
      }
    }

    &:hover svg {
      @mixin genericFocusColor var(--color_grey-200), icon;
    }
  }
}
